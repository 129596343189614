<template>
  <footer>
    <div class="footer-container">
      <v-col lg="12" md="12" xs="12" sm="12">
        <v-row class="mt-0">
          <v-col lg="6" md="12" xs="12" sm="12">
            <div>
              <p id="company_info">Copyright © {{ currentYear }} {{ companyName }}</p>
            </div>
          </v-col>
          <v-col lg="6" md="12" xs="12" sm="12">
            <div class="social-icons">
              <a href="https://www.facebook.com/relearn.nando" target="_blank"><i
                  class="fab fa-facebook-square"></i></a>
              <a href="https://www.linkedin.com/company/re-learn/" target="_blank"><i class="fab fa-linkedin"></i></a>
              <a href="https://www.instagram.com/relearn_official/" target="_blank"><i class="fab fa-instagram"></i></a>
              <a href="https://www.re-learn.eu/" target="_blank"><i class="fa-solid fa-window-maximize"></i></a>
            </div>
          </v-col>
        </v-row>
      </v-col>

    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      companyName: "Re Learn srl",
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
<style>
.footer-container {
  width: 50%;
  margin: auto;
}

#company_info {}

@media only screen and (max-width: 600px) {
  .footer-container {
    width: 100%;
  }

  .social-icons {
    display: inline !important;
  }

  #company_info {
    margin-top: 0px;
  }

  .col {
    flex-basis: auto !important;
  }
}


.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.social-icons {
  width: 100%;
}

.social-icons a {
  padding: 0.5rem;
}
</style>