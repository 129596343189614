<template>
  <div>
    <v-toolbar class="transparent">
      <v-row>
        <v-col lg="12">
          <v-app-bar-title>
            <h8 style="font-size: 0.85rem;">NANDO Waste Analytics Dashboard</h8>
          </v-app-bar-title>
          <v-btn class="floating-btn" style="top: 0.5vh !important;" icon
            @click="languageMenuOpened = !languageMenuOpened"><v-icon>mdi-flag</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <language-switcher-right-drawer :languageMenuOpened="toggleOpenSwitch"
      @update="languageMenuOpened = false"></language-switcher-right-drawer>
  </div>
</template>


<script>
import LanguageSwitcherRightDrawer from "./LanguageSwitcherRightDrawer.vue";
export default {
  components: { LanguageSwitcherRightDrawer },
  data() {
    return { languageMenuOpened: false };
  },
  computed: {
    toggleOpenSwitch() {
      return this.languageMenuOpened;
    },
  },
};
</script>

<style>

</style>