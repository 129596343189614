
<template>
  <v-app>
    <div id="app" style="max-width: 100%; overflow-x: none; height: 100%;">
      <router-view />
      <footer-component />
    </div>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import AppFooter from './components/AppFooter.vue'
export default {
  name: "App",
  mounted: async function () {
    try {
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      if (token) {
        //console.log(session);
      } else {
        this.goToLogin();
      }
    } catch {
      this.goToLogin();
    }
  },
  components: {
    'footer-component': AppFooter
  },
  methods: {
    goToLogin() {
      this.$router.push("Login");
    },
    async logout() {
      try {
        await Auth.signOut();
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-style: normal;
  font-family: "Codec Pro";
  src: url("../public/fonts/Codec Pro/CodecPro-Bold.ttf");
}

@font-face {
  font-style: normal;
  font-family: "Codec Pro ExtraBold";
  src: url("../public/fonts/Codec Pro/CodecPro-ExtraBold.ttf");
}

$body-font-family: "Codec Pro", sans-serif !important;
$heading-font-family: $body-font-family !important;

$title-font: "Codec Pro ExtraBold", sans-serif !important;

.v-application[class*="text-"] * {
  font-family: $body-font-family, sans-serif !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-container {
  height: 100vh;
}

#app .v-container {
  background-color: #e9f5e7;
}

.page {
  /*background-image: url("./assets/ReLearn_background.png");
  background-repeat: no-repeat;
  background-size: cover;*/
  background-color: #e9f5e7;

}

#app [class*="text-"] {
  font-family: "Codec Pro" !important;
}

#app [class*="-bold"] {
  font-family: "Codec Pro ExtraBold" !important;
}

b {
  font-family: "Codec Pro ExtraBold" !important;
  text-transform: uppercase !important;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

input {
  margin-right: 10px;
}
</style>
