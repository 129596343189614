import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
    {
        path: '/main',
        name: 'Main',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Main.vue'),
    },

    {
        path: '/resetpassword',
        name: 'Reset',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue'),
    },
];

const router = new VueRouter({
mode : 'history',
    routes,
});

export default router;
