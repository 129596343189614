import '@fortawesome/fontawesome-free/css/all.css';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import Amplify from 'aws-amplify';
import axios from 'axios';
import "flag-icons/css/flag-icons.min.css";
import "mapbox-gl/dist/mapbox-gl";
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';
import VueMaterial from 'vue-material';
import 'vue-material/dist/theme/default.css';
import 'vue-material/dist/vue-material.min.css';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import awsconfig from './aws-exports';
import { DASHBOARD_API_BASE_URL, OLD_API_RESOURCE } from './config';
import './css/dashboard.css';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import vClickOutside from 'v-click-outside';
import VueApexCharts from 'vue-apexcharts'; //chart plots
import { LControlZoom, LMap, LMarker, LTileLayer } from 'vue2-leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control-zoom', LControlZoom)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Amplify.configure(awsconfig);

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VueAxios, axios)
Vue.use(VueI18n)
Vue.use(VueMaterial)
Vue.use(vClickOutside) // useful for menus
Vue.use(FontAwesomeIcon)

Vue.component('apexchart', VueApexCharts, )
window.Apex.chart = { fontFamily: "Codec Pro" };

library.add(faTwitter, faUserSecret)

Vue.prototype.$apiUrl = DASHBOARD_API_BASE_URL
Vue.prototype.$scrollTop = function () {

  window.scrollTo(0, 0);
}
Vue.mixin({
  computed: {
    isMobileDevice() {
      // Property for managing drawer (could be annoying to have same drawer as desktop version on mobile)
      //var isMobile = navigator.userAgentData.mobile; //resolves true/false
      //return true;!isMobile &&
      if ( screen.width > 850) return false;
      else return true;
    },
    $resource: {
      get: function () { return `${OLD_API_RESOURCE}/` }, set: function (resource) {
        globalData.$data.$resource = resource;
      }
    }, // PAY ATTENTION TO THIS, AS IT CONFIGURES THE ENTIRE ENVIRONMENT
    $graphsDates: {
      get: function () { return globalData.$data.$graphsDates },
      set: function (graphsDates) {
        globalData.$data.$graphsDates = graphsDates;
        // WILL emit the same message as of when switching from station to another one. 
        this.$root.$emit("setGlobalGraphsDates", "hi");

      }
    },
    $buildingsStations: {
      get: function () { return globalData.$data.$buildingsStations },
      set: function (buildingsStations) { globalData.$data.$buildingsStations = buildingsStations; }
    },

    $customers: {
      get: function () { return globalData.$data.$customers },
      set: function (customers) { globalData.$data.$customers = customers; }
    },

    $selectedBuilding: {
      get: function () { return globalData.$data.$selectedBuilding },
      set: function (selectedBuilding) { globalData.$data.$selectedBuilding = selectedBuilding; }
    },
    $selectedStation: {
      get: function () { return globalData.$data.$selectedStation },
      set: function (selectedStation) { globalData.$data.$selectedStation = selectedStation; }
    },

    $selectedCustomer: {
      get: function () { return globalData.$data.$selectedCustomer },
      set: function (selectedCustomer) { globalData.$data.$selectedCustomer = selectedCustomer; }
    },
    $userRole: {
      get: function () { return JSON.parse(localStorage.getItem('userRole')) },
      set: function (userRole) {
        localStorage.setItem('userRole', JSON.stringify(userRole));
      }
    },
    $userData: {
      get: function () { return JSON.parse(localStorage.getItem('userData')) },
      set: function (userData) {
        localStorage.setItem('userData', JSON.stringify(userData));
      }

    }

  }
});

let globalData = new Vue({
  data: { $resource: '', $selectedStation: '', $selectedBuilding: '', $selectedCustomer: '', $graphsDates: [], $buildingsStations: [], $customers: [], }, router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

//console.log(globalData)


