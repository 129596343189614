<template>
  <v-navigation-drawer v-click-outside="onClickClose" v-if="opened" height="auto" width="20%" right permanent stateless
    fixed dense rounded>
    <v-list v-if="opened">
      <v-list-item @click="
        changeLocale('en');
      onClickClose();
      "><v-list-item-icon><v-btn><v-icon class="fi fi-gb"></v-icon></v-btn></v-list-item-icon></v-list-item>
      <v-list-item @click="
        changeLocale('it');
      onClickClose();
      "><v-list-item-icon><v-btn><v-icon class="fi fi-it"></v-icon></v-btn></v-list-item-icon></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
      
    
<script>
export default {
  props: ["languageMenuOpened"],
  methods: {
    changeLocale: function (loc) {
      switch (loc) {
        case "en":
          this.$i18n.locale = "en";
          break;
        case "it":
          this.$i18n.locale = "it";
          break;
        default:
          this.$i18n.locale = "en";
      }

      this.$forceUpdate();
    },
    onClickClose() {
      this.$emit("update", false);
      //console.log("ciao");
    },
  },
  computed: {
    opened: function () {
      return this.$props.languageMenuOpened;
    },
  },
};
</script>
    
<style scoped>
.v-navigation-drawer {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: auto !important;
  text-align: center !important;
  right: 10px;
  top: 10px !important;
}
</style>