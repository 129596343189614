<template>
  <div>
    <app-bar-login></app-bar-login>
    <div class="container">
      <v-row>
        <v-spacer></v-spacer>

        <v-col cols="12" lg="5">
          <v-form>
            <v-img class="mx-auto" src="../assets/relearn.png" max-width="280" max-height="300"></v-img>
            <v-text-field label="E-mail" v-model="email" type="text" autofocus prepend-icon="mdi-email">
            </v-text-field>
            <v-text-field v-model="password" label="Password" prepend-icon="mdi-lock-outline" type="password">
            </v-text-field>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-row v-if="errorLogin">
              <v-spacer></v-spacer>
              <v-alert v-if="errorLogin" dense outlined dismissible type="error">
                {{ errorLoginMessage }}
              </v-alert>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
          <v-form v-if="needsVerificationAgain">
            <v-row>
              <v-col cols="6">
                <v-text-field :label="$t('signupPage.verification-code')" v-model="verificationCode"
                  :rules="verificationCodeRules" type="number" autofocus prepend-icon="mdi-account">
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer><v-col cols="6"><v-btn class="nandocolor mt-2" @click="signUpVerifyCode"
                  v-t="'signupPage[\'check-code\']'"></v-btn></v-col>
            </v-row>
            <v-row><v-spacer></v-spacer><v-col lg="6" cols="12"><v-btn class="nandocolor mt-2"
                  @click="resendConfirmationCode"
                  v-t="'signupPage[\'verification-code-resend\']'"></v-btn></v-col><v-spacer></v-spacer></v-row>

            <v-row v-if="errorVerificationCode">
              <v-col cols="12">
                <v-alert v-if="errorVerificationCode" dense outlined type="error">
                  {{ errorSignupMessage }}
                </v-alert>
              </v-col></v-row>
          </v-form>
          <v-row v-if="signupCompletedCodeVerified">
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-alert v-if="signupCompletedCodeVerified" dense outlined type="success" v-t="'signupPage[\'success\']'">
              </v-alert></v-col><v-spacer></v-spacer></v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer><v-col cols="12" lg="5"><v-btn text v-t="'loginPage[\'forgot-password\']'"
            @click="$router.push('resetpassword')"></v-btn></v-col>
        <v-spacer></v-spacer></v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col lg="5" cols="12">
          <v-container class="">
            <v-row>
              <md-button class="nandocolor nando_button mx-auto" @click.stop="login">Log in</md-button>
            </v-row>
            <v-row><v-col></v-col></v-row>

            <v-row><span v-t="'loginPage[\'not-registered\']'" class="mx-auto"></span></v-row>
            <v-row><v-col cols="12"></v-col></v-row>
            <v-row>
              <md-button class="nandocolor nando_button mx-auto" @click="signUp"
                v-t="'loginPage[\'signup\']'"></md-button>
            </v-row>
          </v-container>
        </v-col>
        <v-spacer></v-spacer></v-row>
    </div>
  </div>
</template>

<script>
import AppBarLogin from "../components/AppBarLogin.vue";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  components: { AppBarLogin },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errorLogin: false,
      errorLoginMessage: "",
      loading: false,
      needsVerificationAgain: false,
      signupCompletedCodeVerified: false,
      errorVerificationCode: false,
      errorSignupMessage: "",
      verificationCode: "",
      verificationCodeRules: [(v) => !!v || "Field is required"],
    };
  },
  methods: {
    async login() {
      this.errorLogin = false;

      try {
        this.loading = true;
        await Auth.signIn(this.email, this.password).then(async (val) => {
          this.loading = false;
          //console.log(val);
          if (val) {
            await this.retrieveUserGroup().then(() => {
              this.$router.push("/main");
            });
          }
        });
      } catch (error) {
        console.log(error);
        this.errorLogin = true;
        this.errorLoginMessage = error.message;
        if (error.name == "UserNotConfirmedException") {
          this.needsVerificationAgain = true;
        }
      }
    },

    async resendConfirmationCode() {
      try {
        await Auth.resendSignUp(this.email);
      } catch (err) {
        this.errorVerificationCode = true;
        this.errorSignupMessage = err.message;
        console.log(err.message);
      }
    },
    async signUpVerifyCode() {
      this.signupCompletedCodeVerified = false;

      try {
        this.errorVerificationCode = false;
        await Auth.confirmSignUp(this.email, this.verificationCode).then(() => {
          this.signupCompletedCodeVerified = true;
          this.needsVerificationAgain = false;
        });
      } catch (error) {
        this.errorVerificationCode = true;
        this.errorSignupMessage = error.message;
        console.log(error.message);
      }
    },
    async retrieveUserGroup() {
      var endpoint = this.$resource + "readwriteusers";
      var data = { type: "get_user_role" };
      var session = await Auth.currentSession();
      var token = await session.getIdToken().getJwtToken();
      var username = await session.getIdToken().payload.nickname;

      //console.log("inside retrieve");
      this.$userData = { username };
      await axios
        .post(this.$apiUrl + endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          //console.log(response);

          this.$userRole = response.data.body[0];
        });
    },

    signUp() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped>

</style>
